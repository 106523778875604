import * as Dialog from '@radix-ui/react-dialog';
import { Drawer } from 'vaul';
import { AnimatePresence, motion } from 'framer-motion';
import { useScreen } from '~/hooks/useScreen';
import React from 'react';
import { classNames } from '~/clientUtils';

// A Modal or a Drawer depending on the screen size
export default function Modal(props: { open: boolean; children: React.ReactNode; onOpenChange?: (open: boolean) => void; modalDivClassName?: string }) {
	const { isMobile } = useScreen();

	if (isMobile) {
		return (
			<Drawer.Root open={props.open} onOpenChange={props.onOpenChange} handleOnly>
				<Drawer.Trigger asChild />
				<Drawer.Portal>
					<Drawer.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
					<Drawer.Content className="bg-white fixed bottom-0 h-[80vh] px-6 pt-8 rounded-t-[10px] w-full">
						<div className="absolute top-[18px] left-1/2 transform -translate-x-1/2">
							<Drawer.Handle className="!w-[60px] !bg-gray-300" />
						</div>
						<div className="overflow-y-auto h-full">{props.children}</div>
					</Drawer.Content>
				</Drawer.Portal>
			</Drawer.Root>
		);
	}

	return (
		<Dialog.Root open={props.open} onOpenChange={props.onOpenChange}>
			<Dialog.Trigger asChild />
			<Dialog.Portal>
				<Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
				<AnimatePresence>
					<Dialog.Content asChild>
						<motion.div
							className={classNames(
								'fixed top-1/2 left-1/2 bg-black border border-gray-500 p-8 rounded-md flex flex-col w-full max-w-[500px]',
								props.modalDivClassName,
							)}
							initial={{ opacity: 0, y: '-40%', x: '-50%' }}
							animate={{ opacity: 1, y: '-50%', x: '-50%' }}
							exit={{ opacity: 0, y: '-40%', x: '-50%' }}
							transition={{ duration: 0.3, type: 'spring', bounce: 0 }}
						>
							{props.children}
							<Dialog.Close />
						</motion.div>
					</Dialog.Content>
				</AnimatePresence>
			</Dialog.Portal>
		</Dialog.Root>
	);
}
